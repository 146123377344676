import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import Seo from '../components/SEO';

const Privacy = ({ location }) => {
  const { pathname } = location;
  return (
    <Layout>
      <Seo title="Privacy Policy" slug={pathname} />
      <div className="relative mx-auto overflow-hidden prose max-w-screen-xl py-6 text-gray-800">
        <h1 className="text-center font-bold text-2xl">Privacy Policy</h1>

        <p>
          TAGinstall &quot;the App&quot; provides the ability to install Google
          Tag Manager &quot;the Service&quot; to merchants who use Shopify to
          power their stores. This Privacy Policy describes how personal
          information is collected, used, and shared when you install or use the
          App in connection with your Shopify-supported store.
        </p>

        <h2 className="font-bold text-xl my-3">
          Personal Information the App Collects
        </h2>

        <p>
          When you install the App you give us your consent to process the
          personal data you store in your Shopify account, as described below
          including to automatically access certain types of information from
          your Shopify account:
        </p>
        <ul>
          <li>Themes installed in your shop</li>
        </ul>

        <p>
          Additionally we become a Processor of the personal information we
          collect from you and/or your customers once you have installed the
          App:
        </p>
        <ul>
          <li>
            Information about you and others who may access the App on behalf of
            your store, such as your name, address, email address, phone number,
            and billing information;
          </li>
        </ul>

        <p>
          We collect personal information directly from the relevant individual,
          through your Shopify account, acting as a Processor by using the
          following technologies:
          <ul>
            <li>
              “Cookies” are data files that are placed on your device or
              computer and often include an anonymous unique identifier. For
              more information about cookies, and how to disable cookies, visit
              http://www.allaboutcookies.org.{' '}
            </li>
            <li>
              “Log files” track actions occurring on the Site, and collect data
              including your IP address, browser type, Internet service
              provider, referring/exit pages, and date/time stamps.{' '}
            </li>
            <li>
              “Web beacons,” “tags,” and “pixels” are electronic files used to
              record information about how you browse the Site.
            </li>
          </ul>
          Acting as a Processor means that we collect, store and share certain
          personally identifiable information about you and your clients in
          order to deliver the services we render to you through the App.
        </p>

        <h2 className="font-bold text-xl my-3">
          How Do We Use Your Personal Information?
        </h2>

        <p>
          By using the the App you are giving us your consent to use the
          personal information we collect from you and your customers in order
          to provide the Service and to operate the App. Additionally, we use
          this personal information to:
          <ul>
            <li>Communicate with you;</li>
            <li> Optimize or improve the App;</li>
            <li>
              Provide you with information or advertising relating to our
              products or services;
            </li>
          </ul>
        </p>

        <h2 className="font-bold text-xl my-3">
          Sharing Your Personal Information
        </h2>

        <p>
          Finally, by using the App you are giving us consent to share your
          Personal Information to comply with applicable laws and regulations,
          to respond to a subpoena, search warrant or other lawful request for
          information we receive, or to otherwise protect our rights.
        </p>

        <p>
          Advertising As described above, we use your Personal Information to
          provide you with targeted advertisements or marketing communications
          we believe may be of interest to you. For more information about how
          targeted advertising works, you can visit the Network Advertising
          Initiative’s (“NAI”) educational page at
          http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
        </p>

        {/* <p>
      You can opt out of targeted advertising by: [[INCLUDE OPT-OUT LINKS FROM
      WHICHEVER SERVICES BEING USED. COMMON LINKS INCLUDE: FACEBOOK; GOOGLE;
      BING]] Additionally, you can opt out of some of these services by visiting
      the Digital Advertising Alliance’s opt-out portal at:
      http://optout.aboutads.info/.
    </p> */}

        <p>
          Your Rights within EU and European Economic Area. If you are a
          European resident, you have the right to access personal information
          we hold about you and to ask that your personal information be
          corrected, updated, or deleted. If you would like to exercise this
          right, please contact us through the contact information below.
        </p>

        <p>
          Additionally, if you are a European resident we note that we are
          processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
          Additionally, please note that your information might be transferred
          outside of Europe, including to Canada and the United States.
        </p>

        <h2 className="font-bold text-xl my-3">Data Retention</h2>
        <p>
          When you place an order through the Site, we will maintain your Order
          Information for our records for a reasonable period of time, unless
          and until you ask us to delete this information.
        </p>

        <h2 className="font-bold text-xl my-3">Changes</h2>
        <p>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>

        <h2 className="font-bold text-xl my-3">Contact Us</h2>
        <p>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at support@taginstall.com.
        </p>
      </div>
    </Layout>
  );
};

Privacy.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Privacy;
